import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';

import SpamtestAutoFixTaskService from '../../services/spamtestAutofixTask.service';
import SpamCheckerService from '../../services/spamChecker.service';

import SpamtestAutofixAiDetails from './spamtestAutofixAiDetails.js';

let stepsText = {
	"initial_email_no_risky_elements_initial_seedlist": "Step 1: MailReach is testing your initial email but without the most risky elements (attachments, links, tracking, signature, etc). To see if these might cause the spam.",
	"initial_email_no_risky_elements_initial_seedlist_fail": "MailReach tested your initial email without links, tracking, signature, attachments, images, etc) and it still landed in spam.",
	"initial_email_no_risky_elements_initial_seedlist_success": "Step 1: MailReach tested your initial email without links, tracking, signature, attachments, images, etc). It landed in inbox!",
	"initial_email_other_seedlist": "Step 1: MailReach is testing your email but to a different seed list to make sure there’s no bias.",
	"initial_email_other_seedlist_success": "Step 1: MailReach tested your initial email but this time with a different seed list and it landed in inbox.",
	"initial_email_other_seedlist_fail": "Step 1: MailReach tested your initial email but this time with a different seed list and it still landed in spam.",
	"initial_email_no_risky_elements_other_seedlist": "Step 2: MailReach is testing a version of your email without all the most risky elements (links, tracking, signature, attachments, images, etc) but on a different seed list to make sure there’s no bias.",
	"initial_email_no_risky_elements_other_seedlist_success": "Step 2: MailReach tested your email without all the most risky elements (links, tracking, signature, attachments, images, etc) to a different seed list and it landed in inbox.",
	"initial_email_no_risky_elements_other_seedlist_fail": "Step 2: MailReach tested your email without all the most risky elements (links, tracking, signature, attachments, images, etc) with a different seed list and it still landed in spam.",
	"harmless_email_initial_seedlist": "Now testing a 100% harmless text content to check if your wording is making your land in spam.",
	"harmless_email_initial_seedlist_success": "MailReach tested a 100% harmless content with the initial seed list and got a better score.",
	"harmless_email_initial_seedlist_fail": "MailReach tested a 100% harmless text content and it failed.",
	"harmless_email_other_seedlist": "MailReach is testing a second 100% harmless email from your mailbox, this time with a different seed list to eliminate bias.",
	"harmless_email_other_seedlist_success": "MailReach tested a second 100% harmless email from your mailbox, this time with a different seed list to eliminate bias. And it landed in inbox.",
	"harmless_email_other_seedlist_fail": "MailReach tested a second 100% harmless email from your mailbox, this time with a different seed list to eliminate bias. And it landed in spam.",
}

let finalTexts = {
	"seedlist_bias": {title: 'Your email may have landed in spam due to bias', details: '<p>When MailReach tested your email on a different seed list, it landed in the inbox. This suggests that its initial placement in spam may have been influenced by bias.</p><p>Bias can occasionally occur when running similar spam tests on our seed lists.</p><p>If you have any questions, please reach out to us and include this URL so we can take a closer look.</p>'},
	"wording_problem": {title: 'Your email may contain words or phrases that trigger spam filters', details: '<p>When MailReach tested a 100% harmless text email, it landed in inbox while with your content, the email goes to spam.</p><p>The difference lies in the wording: specific terms or phrases  in your email are likely flagged as spammy.</p><p><b>Recommendation:</b> Review and adjust your wording to remove any potentially risky language.</p>'},
	"reach_support": {title: 'Contact us for further assistance', details: '<p>MailReach couldn’t resolve the spam issue this time.</p><p>Since this feature is still in Beta, please reach out to us via chat so we can review your case and provide personalized support.</p>'},
	"wording_problem_maybe_more": {title: 'The wording is causing spam, maybe other elements', details: '<p>MailReach first tested your initial email without all the most risky elements and it still landed in spam.</p><p>Then, MailReach tested a 100% harmless text email and it landed in inbox.</p><p>That means two things:</p><p>1) There’s something in your wording triggering spam filters.</p><p>2) There might be other elements triggering spam filters: links, tracking, signature, attachments, images.</p><p>If you have any questions, please reach out to us and include this URL so we can take a closer look.</p>'},
}

let elementTexts = {
	'initial_email_no_risky_elements_except_attachments' : {except: 'attachement(s)'},
	'initial_email_no_risky_elements_except_tracking' : {except: 'tracking'},
	'initial_email_no_risky_elements_except_links' : {except: 'link(s)'},
	'initial_email_no_risky_elements_except_signature' : {except: 'signature'},
	'initial_email_no_risky_elements_except_fname' : {except: 'name'}
}


class SpamcheckerAutofixAi extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, spamtestAutofixTask: null, inapp_messages: [], loading: true, details: {}, openKeys: {}};

    this.runSpamcheckAi = this.runSpamcheckAi.bind(this);
		this.reloadSpamcheckerAi = this.reloadSpamcheckerAi.bind(this);
		this.loadSpamcheck = this.loadSpamcheck.bind(this);
		this.showLoaderNextStep = this.showLoaderNextStep.bind(this);
	}

  componentWillMount() {
    SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
      if(response.body){
        this.setState({spamtestAutofixTask: response.body, loading: false})
      }else{
        this.setState({spamtestAutofixTask: null, loading: false})
      }

			if(response.body){
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						8000
					);
				}
			}

			this.showLoaderNextStep()

    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	reloadSpamcheckerAi(){
		if(this.state.spamtestAutofixTask.completed){
			// Dont refresh if the task is already completed
			return;
		}
		SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
			if(response.body){
				this.setState({spamtestAutofixTask: response.body, loading: false})
			}else{
				this.setState({spamtestAutofixTask: null, loading: false})
			}

			if (!response.body){
				setTimeout(
					() => this.reloadSpamcheckerAi(),
					10000
				);
			}else{
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						10000
					);
				}
			}

			this.showLoaderNextStep();
		})
		.catch((error) => {
			this.setState({loading: false});
			toast.error('Oops. An error occured.');
		});
	}

  runSpamcheckAi(){
    SpamtestAutoFixTaskService.create(this.props.match.params.test_id).then((response) => {
			this.setState({spamtestAutofixTask: response.body, loading: false});
      //Launch reloading
			this.reloadSpamcheckerAi();
    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	showLoaderNextStep(){
		if(!this.state.spamtestAutofixTask){
			return;
		}

		if(!this.state.spamtestAutofixTask.process_data){
			return;
		}
		// Check if we have one step in progress
		let stepsInProgress = this.state.spamtestAutofixTask.process_data.filter((step) => !step.completed).length;


		let subSteps = this.state.spamtestAutofixTask.process_data.filter((step) => step['key'] == 'initial_email_isolating_risky_elements');

		let subStepsInProgress = 0;
		if(subSteps.length > 0){
			subStepsInProgress = subSteps[0]['details'].filter((step) => !step.completed).length;
		}

		let totalNextSteps = stepsInProgress + subStepsInProgress;

		if(totalNextSteps == 0 && !this.state.spamtestAutofixTask.completed){
			this.setState({nextStepLoading: true});
		}else{
			this.setState({nextStepLoading: false});
		}

	}

	loadSpamcheck(spamcheckInternalId, key){
		let openKeys = this.state.openKeys;
		if(openKeys[key]){
			openKeys[key] = false;
			this.setState({openDetails: openKeys});
			return;
		}
		openKeys[key] = true;
		this.setState({openDetails: openKeys})
		SpamCheckerService.getAutofix(spamcheckInternalId).then((response) => {
			let a = this.state.details || {};
			a[key] = response.body;
			this.setState({details: a});
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
			this.setState({loadNextTest: false});
		});
	}

	componentWillReceiveProps(nextProps) {
    this.loadNextSteps(nextProps);
	}

	handleCloseModal(){
		this.setState({showNextStepModal: null})
	}

	render() {
    if(this.state.loading){
      return(
        <div className="card text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

    if(!this.state.spamtestAutofixTask && !this.state.loading){
      return (
        <div className="card upgrade-spamchecker run-autofix-ai">
            <div className="col-md-12 text-left">
              <h2>Our AI can help you find out why this email ends up in spam.</h2>
              <p>Since {this.props.currentTest.account.email} has a reputation score of {this.props.currentTest.account.score}, this test landing in spam on professional inboxes is likely due to the content of this email. Let MailReach try to improve your deliverability by running multiple tests from your mailbox. This is a Beta feature. </p>
              <p><button onClick={this.runSpamcheckAi} className="btn btn-primary green-bck">Run MailReach AI</button></p>
            </div>
            <div className="clearfix"></div>
          </div>
      )
    }

		if(this.state.spamtestAutofixTask && this.state.spamtestAutofixTask.process_data.length == 0){
      return (
				<div className="card autofix-loading-no-card col-md-12 no-padding-left">
					 <div className="txt pull-left col-md-10 text-left">
						<div className="pull-left">
							Analyzing your email and initializing Autofix AI
						</div>
						<div className="loader">
												<Loader
												 type="Oval"
												 color="#b7b7b7"
												 height="15"
												 width="15"
												/>
											</div>
					 </div>
				</div>
      )
    }

		return (
      <div>


			{this.state.spamtestAutofixTask.process_data.map((detail, index) => {

				if(detail['final_key']){
					if(detail['final_key'] != 'list_spammy_elements'){
						return(
							<div className="card card-autofix-progress col-md-12">
								 <div className="txt pull-left col-md-10 text-left">
									<h2 className="autofix-result-title">Results : {finalTexts[detail['final_key']]['title']}</h2>

									<div dangerouslySetInnerHTML={{__html: finalTexts[detail['final_key']]['details']}}
										/>
								 </div>
							</div>
						)
					} else {
						return(
							<div className="card card-autofix-progress col-md-12">
								 <div className="txt pull-left col-md-10 text-left">
									<h2 className="autofix-result-title">Conclusion: {this.state.spamtestAutofixTask.process_data.find((element) => detail['key'] == 'initial_email_isolating_risky_elements').filter((details) => detail.score < 7).length} element(s) identified to cause spam</h2>

									<div>
									{this.state.spamtestAutofixTask.process_data.find((element) => detail['key'] == 'initial_email_isolating_risky_elements').filter((details) => detail.score < 7).map((detail, index) => {
										return (
											<>
												{elementTexts[detail['key']]['except']}
											</>
										)
									})}


									<p>Remove all these elements from your email and/or replace them with other ones</p>
								 </div>
								 </div>
							</div>
						)
					}

				} else if (detail['key'] == 'initial_email_isolating_risky_elements') {
					return(
						<>
							{detail['details'].map((detailInner, index) => {
								return(
									<>
									{(detailInner.completed) &&
										<>
											<div className="card card-autofix-progress col-md-12">
														<>
															<div className="txt pull-left col-md-10 text-left">
															MailReach tested your initial email without risky elements, except the {elementTexts[detailInner['key']]['except']}. Goal: check if the {elementTexts[detailInner['key']]['except']} is causing spam. {(detailInner.score > 7) ? "It's not" : 'It is!'}
														 </div>

														 <Tooltip title="Well done 🙌" placement="bottom">
															 <span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(detailInner['test_id'], detailInner['key'])}>
																 Done &nbsp;
																 {!this.state.openKeys[detailInner['key']] &&
																	 <i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
																 }
																 {this.state.openKeys[detailInner['key']] &&
																	 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
																 }
															 </span>
														 </Tooltip>
														</>
												 {this.state.openKeys[detailInner['key']] &&
													 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={detailInner['addresses']} details={this.state.details[detailInner['key']]} />
												 }
											</div>
											</>
									}

									{(!detailInner.completed) &&
										<>

											<div className="card card-autofix-progress col-md-12">
												 <div className="txt pull-left col-md-10 text-left">
												 Now testing your initial email without risky elements, except the {elementTexts[detailInner['key']]['except']}. Goal: check if the {elementTexts[detailInner['key']]['except']} is the spam cause.
												 </div>

												 <Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
													 <div className="score running pull-right pulse">
														 Running
													 </div>
												 </Tooltip>
											</div>
										</>
									}
								</>
								)
							})}
						</>
					)

				} else {
				return(
					<>
					{(!detail.completed) &&
						<div className="card card-autofix-progress col-md-12">
							 <div className="txt pull-left col-md-10 text-left">
								{stepsText[detail['key']]}
							 </div>

							 <Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
								 <div className="score running pull-right pulse">
									 Running
								 </div>
							 </Tooltip>
						</div>
					}

					{(detail.completed) &&

						<div className="card card-autofix-progress col-md-12">

								{(detail.score > 7) &&
									<>
										<div className="txt pull-left col-md-10 text-left">
			 							 	{stepsText[`${detail['key']}_success`]}
			 						 </div>

			 						 <Tooltip title="Well done 🙌" placement="bottom">
			 							 <span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
			 								 Done &nbsp;
			 								 {!this.state.openKeys[detail['key']] &&
			 									 <i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
			 								 }
			 								 {this.state.openKeys[detail['key']] &&
			 									 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
			 								 }
			 							 </span>
			 						 </Tooltip>
									</>
								}
								{(detail.score < 7) &&
									<>
										<div className="txt pull-left col-md-10 text-left">
											{stepsText[`${detail['key']}_fail`]}
										</div>

										 <span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(detail['test_id'], detail['key'])}>
											 Failed &nbsp;
											 {!this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
											 }
											 {this.state.openKeys[detail['key']] &&
												 <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											 }
										 </span>
										</>
								}

							 {this.state.openKeys[detail['key']] &&
								 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={detail['addresses']} details={this.state.details[detail['key']]} />
							 }
						</div>

					}
				</>
			)
		}
		})

	}

	{(this.state.nextStepLoading) &&
					<div className="card autofix-loading-no-card col-md-12">
						 <div className="txt pull-left col-md-10 text-left">
						 		<div className="pull-left">
									Preparing the next analysis, this might take a moment...
								</div>
							<div className="loader">
								            <Loader
								             type="Oval"
								             color="#b7b7b7"
								             height="15"
								             width="15"
								            />
								          </div>
						 </div>

					</div>
			}

      </div>

		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpamcheckerAutofixAi));
